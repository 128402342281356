var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId],
            scrollable: "",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "div",
                          { staticClass: "history-button lock-container" },
                          [
                            _vm.isLockVisible
                              ? [
                                  _c("div", { staticClass: "ml-1 mr-1" }, [
                                    _vm._v(_vm._s(_vm.$t("lock"))),
                                  ]),
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      disabled: _vm.isLockReadOnly,
                                      switch: "",
                                    },
                                    model: {
                                      value: _vm.department.readOnly,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.department,
                                          "readOnly",
                                          $$v
                                        )
                                      },
                                      expression: "department.readOnly",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "secondary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.historyShow = true
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "clock-rotate-left"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("button.history")) + " "
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _vm.exists && _vm.canView("STAFF")
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { "margin-right": "auto" },
                          attrs: { size: "sm", variant: "secondary" },
                          on: { click: _vm.viewStaff },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("button.view_staff")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isAccessDenied && (_vm.canEdit() || !_vm.exists)
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.disableOk,
                            size: "sm",
                            variant: "success",
                          },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.restrictedRequiredField != null
                          ? _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                              [
                                _vm.$t("department.title").toLowerCase(),
                                _vm.restrictedRequiredField,
                              ]
                            )
                          : _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity",
                              [_vm.$t("department.title").toLowerCase()]
                            )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: "danger",
                      dismissible: "",
                      show: _vm.showError,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "triangle-exclamation"] },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm.isDepartmentVisible
                  ? _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: _vm.$t("department.field.parent_department"),
                          "label-for": "parent",
                        },
                      },
                      [
                        _c(
                          "b-input-group",
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "parent",
                                type: "text",
                                readonly: "",
                              },
                              model: {
                                value: _vm.parent.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.parent, "name", $$v)
                                },
                                expression: "parent.name",
                              },
                            }),
                            !_vm.isDepartmentReadOnly
                              ? _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { size: "sm" },
                                        on: { click: _vm.modalRemove },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("button.remove")))]
                                    ),
                                    _vm.canList()
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              size: "sm",
                                              variant: "info",
                                            },
                                            on: { click: _vm.modalSelect },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("button.select"))
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.isNameVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("department.field.name"),
                                      "label-for": "name",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }",
                                            },
                                          ],
                                          attrs: {
                                            id: "name",
                                            type: "text",
                                            "data-vv-as": _vm.$t(
                                              "department.field.name"
                                            ),
                                            "data-vv-name": "department.name",
                                            maxlength: _vm.maxNameLength,
                                            "data-vv-delay": "500",
                                            readonly: _vm.isNameReadOnly,
                                            autofocus: true,
                                            state:
                                              _vm.fieldValidateUtil.stateValidate(
                                                _vm.isReadOnly,
                                                _vm.veeFields,
                                                _vm.errors,
                                                "department.name"
                                              ),
                                            trim: "",
                                          },
                                          nativeOn: {
                                            keydown: function ($event) {
                                              return _vm.keydownHandler.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.department.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.department,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "department.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: { "d-block": _vm.showNameError },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "department.name"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["name"] != null
                          ? _vm._l(
                              _vm.customFieldMap["name"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "name" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id: `${_vm.componentId}_${field.name}`,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target: `${_vm.componentId}_${field.name}`,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.department[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.department,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "department[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isIdentifierVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("field.identifier"),
                                      "label-for": "identifier",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "identifier",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("field.identifier"),
                                            "data-vv-name":
                                              "department.identifier",
                                            maxlength: _vm.maxIdentifierLength,
                                            readonly: _vm.isIdentifierReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.department.identifier,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.department,
                                                "identifier",
                                                $$v
                                              )
                                            },
                                            expression: "department.identifier",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["identifier"] != null
                          ? _vm._l(
                              _vm.customFieldMap["identifier"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "identifier" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id: `${_vm.componentId}_${field.name}`,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target: `${_vm.componentId}_${field.name}`,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.department[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.department,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "department[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.customFieldMap["default"] != null
                          ? _vm._l(
                              _vm.customFieldMap["default"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id: `${_vm.componentId}_${field.name}`,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target: `${_vm.componentId}_${field.name}`,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.department[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.department,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "department[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isTagVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("TagList", {
                                      attrs: {
                                        holderId: _vm.id,
                                        tags: _vm.tags,
                                        readOnly: _vm.isTagReadOnly,
                                      },
                                      on: { modified: _vm.tagsModified },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["tags"] != null
                          ? _vm._l(
                              _vm.customFieldMap["tags"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "tags" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id: `${_vm.componentId}_${field.name}`,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target: `${_vm.componentId}_${field.name}`,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.department[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.department,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "department[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isColorVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("Color", {
                                  attrs: {
                                    disabled: _vm.isColorReadOnly,
                                    update: _vm.updatedColor,
                                  },
                                  model: {
                                    value: _vm.department.color,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.department, "color", $$v)
                                    },
                                    expression: "department.color",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["color"] != null
                          ? _vm._l(
                              _vm.customFieldMap["color"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "color" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id: `${_vm.componentId}_${field.name}`,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target: `${_vm.componentId}_${field.name}`,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.department[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.department,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "department[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm.isNoteVisible
                  ? _c(
                      "b-form-group",
                      [
                        _c("NoteList", {
                          attrs: {
                            readOnly: _vm.isNoteReadOnly,
                            notes: _vm.notes,
                          },
                          on: {
                            add: _vm.addNote,
                            edit: _vm.editNote,
                            toRemove: _vm.removeNote,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.customFieldMap["notes"] != null
                  ? [
                      _c(
                        "div",
                        { staticClass: "container pl-0" },
                        [
                          _c(
                            "b-row",
                            _vm._l(
                              _vm.customFieldMap["notes"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "notes" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id: `${_vm.componentId}_${field.name}`,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target: `${_vm.componentId}_${field.name}`,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.department[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.department,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "department[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
        ],
        2
      ),
      _vm.modalShowSelector
        ? _c("DepartmentSelectorModalForAdmin", {
            attrs: {
              show: _vm.modalShowSelector,
              preselected:
                _vm.parent == null
                  ? null
                  : _vm.parent.path != null
                  ? _vm.parent.path
                  : _vm.parent.uuId != null
                  ? _vm.parent.uuId
                  : null,
              company: _vm.company,
              selectorTitle: _vm.$t("department.title_parent_selector"),
              exclude: _vm.department.uuId,
              nonAdmin: "",
              noSubCompany: "",
              singleSelection: "",
              hideStaffCount: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.modalShowSelector = $event
              },
              ok: _vm.modalSuccessSelector,
            },
          })
        : _vm._e(),
      _vm.showStaffSelector
        ? _c("StaffSelectorModalForAdmin", {
            attrs: {
              show: _vm.showStaffSelector,
              departments: [_vm.department],
              companies: _vm.company ? [_vm.company] : [],
              nonAdmin: "",
              hideOkBtn: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.showStaffSelector = $event
              },
            },
          })
        : _vm._e(),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("GenericHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: _vm.id,
                    entityType: "DEPARTMENT",
                    customFields: _vm.customFields,
                    links: "DEPARTMENT,COMPANY,NOTE,TAG",
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm.state.noteShow
              ? _c("NoteModal", {
                  attrs: { show: _vm.state.noteShow, note: _vm.note },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "noteShow", $event)
                    },
                    toAdd: _vm.toAddNote,
                    toUpdate: _vm.toUpdateNote,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }