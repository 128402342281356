import { cloneDeep } from 'lodash';

export async function updateLocation(holderEntityId, holderEntityService, oldLocation, newLocation) {
  const result = {
    hasError: false,
    errorCodes: []
  }
  const location = cloneDeep(newLocation);
  const originLocation = oldLocation;
  const toAdd = [];
  const unchangedIds = [];
  for (let i = 0, len = location.length; i < len; i++) {
    const comp = location[i];
    const index = originLocation.findIndex(j => j.uuId === comp.uuId);
    if (index == -1) {
      toAdd.push(comp);
    } else {
      unchangedIds.push(comp.uuId);
    }
  }

  const toAddIds = toAdd.map(i => i.uuId);
  const toRemove = originLocation.filter(i => !toAddIds.includes(i.uuId) && !unchangedIds.includes(i.uuId) && i.uuId != null);

  //To add new location if there is any.
  if (toAdd.length > 0) {
    let { hasError=false, errorCode=null } = await holderEntityService.create(holderEntityId, toAddIds)
    .then(response => {
      if (207 == response.status) {
        const list = response.data[response.data.jobCase];
        const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
        const failLocation = toAdd.filter(i => failIds.includes(i.uuId));
        return { hasError: failLocation.length > 0, errorCode: 207 };
      }
      return {};
    })
    .catch(e => {
      if (e.response && 422 == e.response.status) {
        const list = e.response.data[e.response.data.jobCase];
        const existingIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
        const failedList = toAdd.filter(i => !existingIds.includes(i.uuId));
        return { hasError: failedList.length > 0, errorCode: 422 };
      } else {
        return {
          hasError: true
          , errorCode: e != null && e.response != null ? e.response.status : null
        }
      }
    });

    if (hasError) {
      result.hasError = true;
      result.errorCodes.push(errorCode);
    }
  }

  //Try remove location which user wants to remove if there is any
  if (toRemove.length > 0) {
    const clues = ['OK', 'Unknown_relation'];
    let { hasError=false, errorCode=null } = await holderEntityService.remove(holderEntityId, toRemove.map(i => i.uuId))
      .then(response => {
        if (207 == response.status) {
          const list = response.data[response.data.jobCase];
          const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
          return { hasError: toRemove.filter(i => failIds.includes(i.uuId)).length > 0, errorCode: 207 };
        }
        return {};
      })
      .catch(e => {
        if (e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          const failIds = list.filter(i => !clues.includes(i.clue)).map(i => i.args[0]);
          return { hasError: toRemove.filter(i => failIds.includes(i.uuId)).length > 0, errorCode: 422 };
        } else {
          return { hasError: true, errorCode: e != null && e.response != null ? e.response.status : null }
        }
      });
    if (hasError) {
      result.hasError = true;
      result.errorCodes.push(errorCode);
    }
  }
  return result;
}